import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import login from './login';
import Home from './Home';
import Dashboard from './Dashboard';
import CommonTables from './CommonTables';
import CommonTablesD from './CommonTablesD';
import PaginationExample from './PaginationExample';

class App extends Component {
  render() {
    return (
    <Router>
        <div style={{backgroundColor:'#000'}}>
          <h2>Welcome to React Router Tutorial</h2>
          
          <hr />
                    
          <Route exact path='/' component={login} />
          <Route exact path='/Dashboard' component={Dashboard} />
          <Route exact path='/Home' component={Home} />
          <Route exact path='/CommonTables' component={CommonTables} />
          <Route exact path='/CommonTablesD' component={CommonTablesD} />
          <Route exact path='/PaginationExample' component={PaginationExample} />

         
        </div>
      </Router>
    );
  }
}

export default App;