import React, { useState , useEffect} from 'react'
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import PaginationExample from './PaginationExample';
const CommonTables = (props) => {
    let perPage = 10;
    let pageCount = 0;
    let a = [];
    let b = [];
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);    
    const [headers, setHeaders] = useState([]);  
    const [tableData, setTableData] = useState([]);   
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setOffset(selectedPage * perPage);
        setCurrentPage(selectedPage);       
        getData()
      
    };
    useEffect(() => {
        getData();
    });
    const getData = async  () =>  {        
        const response = await  fetch (`https://www.fourmodules.com/testReact.php?crossorigin=1&page=${currentPage}`);
        const res = await response.json();   
                
                var data = res.data;
                pageCount = res.total_page;
                console.log(res.data);
                // setTableData([...tableData, res.data.data]);  
                // setTableData(res.data);
                // setHeaders(res.headers);
                // console.log(tableData)
            
    };
    return (
        
        <div>
            <PaginationExample tableData={tableData} tableHeaders={headers} />
            <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
        </div>
    )
}
export default CommonTables