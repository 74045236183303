import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
class Dashboard extends Component {
  render() {
    return (
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ul className="navbar-nav mr-auto">
            <li><Link to={'/Home'} className="nav-link">Home</Link></li>
            <li><Link to={'/Dashboard'} className="nav-link">Dashboard</Link></li>
            <li><Link to={'/'} className="nav-link">Login</Link></li>
          </ul>
          </nav>
          <h2>Dashboard</h2>
        </div>
    );
  }
}

export default Dashboard;