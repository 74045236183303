import React, { PureComponent } from 'react'
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import PaginationExample from './PaginationExample';
export class CommonTablesD extends PureComponent {

    constructor(props) {
        super(props)
    
        this.state = {
            offset: 0,
            tableData: [],
            orgtableData: [],
            perPage: 10,
            currentPage: 0,
            headers: [],
            counter:1
        }
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.getData(this.state.currentPage)
        });

    };

    

    componentDidMount(){
        this.getData("0");
    }

    getData(data) {        
        const currentPage = data;
        axios
            .get(`https://www.fourmodules.com/testReact.php?crossorigin=1&page=${currentPage}`)
            .then(res => {
                
                var data = res.data.data;            

                this.setState({
                    pageCount: res.data.total_page,
                    tableData:res.data.data,
                    headers:res.data.headers
                })
                
            });
    }

    render() {
        console.log("here");
        return (
            <div>
                <PaginationExample tableData={this.state.tableData} tableHeaders={this.state.headers}/>
                <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
            </div>
            

           
        )
    }
}

export default CommonTablesD