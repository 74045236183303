import React from 'react'
    

const PaginationExample = (props) => {
   
         return (
            <div style={{backgroundColor:'#fff'}}>
                 <h1>Gk Techy</h1>
                 <table border="1">                 
                     <thead>
                        <tr>
                           
                            {
                                props.tableHeaders.map((theader, i) => (                                    
                                    <th key={i}>
                                        {theader}
                                    </th>
                                ))
                            }                        
                        </tr>
                     </thead>
                     <tbody>
                        {         
                          props.tableData.map((tdata, i) => (                                                        
                                <tr key={i}>                                                                
                                   
                                    {   
                                          props.tableHeaders.map((theader, i) => (                                            
                                            <td key={i}>
                                                {tdata[theader]}                                                
                                            </td>
                                        ))
                                       
                                    } 
                                    
                                </tr>                                                            
                          ))
                        }

                     </tbody>
                 </table>   
            </div>
        )
    }


export default PaginationExample