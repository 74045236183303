import React, { Component } from 'react';
import Axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showData : '',
      password:'',
      email:''
    }
}
  onFormSubmit = (e) => {
    e.preventDefault();
    this.setState({
      showData : '1'
    })
    Axios.post(`https://www.fourmodules.com/testReact.php?crossorigin=1`, {      
      email: this.state.email,
      password: this.state.password,
      action:'login'
    })
    .then(function (response) {
     console.log(response);
     
     
    })
     
    
  }
  handleInput = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }
  render() {
    return (
      <div className="container-wrapper background-opacity">
      <div id="wrapper">          
          <div className="card card-primary card-authentication1 mx-auto animated">
              <div className="card-body login_form_div"> 
                  <div className="card-content p-2">
                      <div className="card-title text-uppercase text-center " ><Link to={'/Dashboard'} className="nav-link">Dashboard11</Link>Sign In{this.state.email}</div>
                  </div>
              </div>
  
              <div className="card-body enable_form_div"> 
                  <div class="card-content p-3">
                      <form name="login_form" id="login_form" method="post">
                        <p class="error-message"></p>
                        <div class="position-relative has-icon-right form-group">
                            <input type="text" name="email" id="email_id" autofocus class="form-control form-control-rounded" placeholder="Username/Email/Mobile" onChange={(e) => { this.handleInput(e) }} value={this.state.email}/>
                            <input type="hidden" name="url" id="url" />
                            <div class="form-control-position">
                                <i class="icon-user"></i>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="position-relative has-icon-right">
                                <label for="exampleInputPassword" class="sr-only">Password</label>
                                <input type="password" name="password" id="password" value={this.state.password} class="form-control form-control-rounded" placeholder="Password" onChange={(e) => { this.handleInput(e) }}/>
                                <div class="form-control-position">
                                    <i class="fa fa-eye" ></i>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mr-0 ml-0">
                            <div class="form-group col-6">
                                <div class="demo-checkbox">
                                    <input type="checkbox" id="user-checkbox" class="filled-in chk-col-primary" name="remember" value="1" checked="" />
                                    <label for="user-checkbox" class="user-checkbox">Remember Me</label>
                                </div>
                            </div>
                            <div className="form-group col-6 text-right">
                                <a id ="forgot_password">Forgot Password</a>
                            </div>
                        </div> 
                        <input value="Sign In" type="submit" id="login_btn123" className="btn btn-primary btn-round btn-block waves-effect waves-light" onClick={(e) => { this.onFormSubmit(e) }}/>

                    </form>
                  </div>
              </div>
          </div>
          <a className="back-to-top"><i class="fa fa-angle-double-up"></i> </a>
      </div>
  </div>
    );
  }
}

export default login;